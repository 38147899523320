@import "./theme.scss";

html,
.root {
  font-size: 16px;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5rem;
  font-weight: $regular;
  color: $neutral7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
p {
  margin-bottom: 1rem;
}
// For all the heading elements add the default margin and weight
h1,
h2,
h3,
h4,
h5 {
  line-height: 1.3;
  margin: 3rem 0 1.38rem;
  font-weight: $mediumWeight;
}
/* 
For each base element type add its base properties
N.B. The default styles for each HTML tag have been left as per the old design system, this is to allow a phased transfer to the new design system.
As more and more of the pages/components get moved over to the new design system, fewer and fewer of the elements will depend on the base styling. 
To use the new design system styles see instructions below.
*/

#webpack-dev-server-client-overlay {
  display: none;
}

h1 {
  font-size: $heading-1;
  margin-top: 0;
  color: $neutral7;
}
h2 {
  font-size: $heading-2;
  color: $neutral7;
}
h3 {
  font-size: $heading-3;
  text-transform: uppercase;
  font-weight: 700;
}
h4 {
  font-size: $heading-4;
}
h5 {
  font-size: $heading-5;
}
h6 {
  font-size: $heading-6;
}
h7 {
  font-size: $heading-7;
}
p,
ul,
ol {
  font-size: 16px;
}
a {
  color: $neutral4;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

/* 
The following global classes define the new design system styles for each element and its variations. 
To use the new design system style simply add a className=".property" in the JSX
Note that the . notation (global classes) also allows for visual semantics and typographical hierarchy. 
i.e. you want an <h2> tag for SEO/accessibility but want it to look like an H1 you can add the .h1 class to a h2 tag
*/

.h1 {
  font-size: $heading1;
  line-height: $superWide;
}
.h1_bold {
  @extend .h1;
  font-weight: $bold;
}
.h1_semi_bold {
  @extend .h1;
  font-weight: $semiBold;
}

.h2 {
  font-size: $heading2;
  line-height: $superWide;
}
.h2_bold {
  @extend .h2;
  font-weight: $bold;
}
.h2_semi_bold {
  @extend .h2;
  font-weight: $semiBold;
}

.h3 {
  font-size: $heading3;
  line-height: $extraWide;
}
.h3_bold {
  @extend .h3;
  font-weight: $bold;
  line-height: $superWide;
}
.h3_semi_bold {
  @extend .h3;
  font-weight: $semiBold;
}

.h4 {
  font-size: $heading4;
  line-height: $wide;
}
.h4_bold {
  @extend .h4;
  font-weight: $bold;
  line-height: $superWide;
}
.h4_semi_bold {
  @extend .h4;
  font-weight: $semiBold;
}

.h5 {
  font-size: $heading5;
  line-height: $wide;
}
.h5_bold {
  @extend .h5;
  font-weight: $bold;
  line-height: $superWide;
}
.h5_semi_bold {
  @extend .h5;
  font-weight: $semiBold;
  line-height: $superWide;
}
// Add the sub heading specific styles
.sub_heading_regular {
  font-weight: $regular;
  font-size: $subHeading;
  line-height: $mediumWide;
}
.sub_heading_medium {
  font-weight: $mediumWeight;
  font-size: $subHeading;
  line-height: $mediumWide;
}
.sub_heading_semi_bold {
  font-weight: $semiBold;
  font-size: $subHeading;
  line-height: $mediumWide;
}

// Add the paragraph styles. p1 regular is considered default
.p1_regular {
  font-weight: $regular;
  font-size: $body;
  line-height: $wide;
}
.p1_medium {
  @extend .p1_regular;
  line-height: $medium;
  font-weight: $mediumWeight;
}
.p1_semi_bold {
  @extend .p1_regular;
  line-height: $superWide;
  font-weight: $semiBold;
}
.p1_bold {
  @extend .p1_regular;
  line-height: $superWide;
  font-weight: $bold;
}

// p2 styles
.p2_regular {
  font-weight: $regular;
  font-size: $bodySmall;
  line-height: $wide;
}
.p2_medium {
  @extend .p2_regular;
  line-height: $thin;
  font-size: $bodySmall;
  font-weight: $mediumWeight;
}
.p2_semi_bold {
  @extend .p2_regular;
  line-height: $thin;
  font-size: $bodySmall;
  font-weight: $semiBold;
}
.p2_bold {
  @extend .p2_regular;
  line-height: $superWide;
  font-size: $bodySmall;
  font-weight: $bold;
}

// p3 styles
.p3_regular {
  font-weight: $regular;
  font-size: $bodyExtraSmall;
  line-height: $extraThin;
}
.p3_medium {
  @extend .p3_regular;
  line-height: $extraThin;
  font-size: $bodyExtraSmall;
  font-weight: $mediumWeight;
}
.p3_semi_bold {
  @extend .p3_regular;
  line-height: $superWide;
  font-size: $bodyExtraSmall;
  font-weight: $semiBold;
}
.p3_bold {
  @extend .p3_regular;
  line-height: $superWide;
  font-size: $bodyExtraSmall;
  font-weight: $bold;
}

// Captions
.caption_medium {
  line-height: $extraThin;
  font-size: $bodySmall;
  font-weight: $mediumWeight;
}
.caption_regular {
  line-height: $extraThin;
  font-size: $bodySmall;
  font-weight: $regular;
}
