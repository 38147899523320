@import "../../Styles/theme.scss";

.report {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid $neutral2;
  &:hover {
    background-color: $neutral1;
    cursor: pointer;
  }
}

.report_element {
  display: flex;
  padding: 18px 0 18px 22px;
  font-size: $bodySmall;
  flex: 1;
  box-sizing: border-box;
}

.report_element_menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  flex: 1;
  padding-right: 24px;
  margin: 10px 0px;
  gap: 10px;
}

.report_element_buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 175px;
}

.kebab_menu {
  background-color: $white;
  position: relative;
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.kebab_menu_hover {
  @extend .kebab_menu;
  cursor: pointer;
  background-color: $neutral3;
}

.menu {
  display: flex;
  flex-direction: column;
  background-color: $white;
  z-index: 200;
  padding: 4px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  position: absolute;
  right: 35px;
  width: 160px;
}

.menu_above {
  @extend .menu;
  top: 0px;
}
.menu_below {
  @extend .menu;
  top: 50px;
}

.error_message {
  margin: 0;
  color: $risk6;
  font-size: $bodySmall;
}

.fixed_width_button {
  width: 168px;
  max-width: 168px;
}

@media (max-width: 1530px) {
  .fixed_width_button {
    width: 140px;
    max-width: 140px;
  }
}
