@import "../../Styles/theme.scss";

.search_result_bucket_container {
  display: flex;
  flex-direction: column;
}

.top_bucket {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 17px;
}

.bottom_bucket {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px 20px;
}

.bucket_title {
  display: flex;
  align-items: center;
  font-weight: $mediumWeight;
  font-size: $heading5;
  color: $neutral7;
}

.bucket_tip {
  font-weight: $regular;
  font-size: $bodyExtraSmall;
  color: $neutral5;
  cursor: pointer;
  display: flex;
  div {
    margin-left: 4px;
    text-decoration: underline;
    font-weight: $mediumWeight;
  }
}

.title_underline {
  display: flex;
  align-self: flex-start;
}

.icon {
  width: 16px;
  height: 16px;
  border: 2px solid $neutral7;
  border-radius: 20px;
  display: flex;
  align-tracks: center;
  justify-content: center;
  margin-right: 6px;
}

@media (min-width: 700px) {
  .bottom_bucket {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 950px) {
  .bottom_bucket {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 1100px) {
  .bottom_bucket {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1400px) {
  .bottom_bucket {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1700px) {
  .bottom_bucket {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
