@import "../../../Styles/theme.scss";

.desktop_instructions {
  display: flex;
  flex-direction: column;
  ol {
    li {
      font-family: "Inter";
      font-size: $bodyMedium;
      font-weight: $regular;
      line-height: 22px;
      text-align: left;
      padding-top: 12px;
      padding-bottom: 12px;
      color: $neutral6;
    }
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
