@import "../../Styles/theme.scss";

.sensor {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border: 1px solid $neutral3;
  border-radius: 8px;

  height: 110px;
  min-height: 110px;

  &:hover > * .sensor_status_tag.offline {
    background-color: $risk3;
  }
}

.hover {
  cursor: pointer;

  &:hover {
    border: 1px solid $neutral4;
  }

  &:active {
    border: 1px solid $neutral5;
  }
}

.sensor_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.sensor_name_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.sensor_name {
  color: $neutral7;

  font-size: $bodyMedium;
  font-weight: $mediumWeight;
  line-height: $thin;

  margin-bottom: 8px;
}

.location {
  color: $neutral5;

  font-size: $bodySmall;
  font-weight: $regular;
  line-height: $mediumWide; /* 142.857% */
}

.sensor_signal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.image {
  width: 66px;
  object-fit: contain;
  align-self: center;
}

.sensor_right {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  gap: 15px;
}

.device_group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.last_online {
  color: $neutral5;

  font-size: $bodyExtraSmall;
  font-weight: $regular;
  line-height: $mediumThin; /* 133.333% */
  margin: 0;
}

.mobile_last_online {
  display: none;
}

.desktop_last_online {
  display: block;
}

@media (max-width: $mobile-break-point) {
  .sensor {
    gap: 10px;

    height: auto;
    min-height: auto;
  }

  .sensor_right {
    flex-direction: column-reverse;
  }

  .desktop_last_online {
    display: none;
  }

  .mobile_last_online {
    display: flex;
  }

  .device_group {
    flex-direction: column-reverse;
  }
}

@media (max-width: 375px) {
  .sensor_right {
    display: none;
  }
}
