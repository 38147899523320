/* 
Measures
*/
$header-height: 62px;
$side-drawer-width: 62px;
$mobile-break-point: 600px;
$tablet-sm-break-point: 768px;
$tablet-break-point: 900px;
$laptop-break-point: 1024px;
$mobile-padding: 12px;
$tablet-padding: 21px;
$desktop-padding: 42px;
$pdf-padding: 42px;
$reduced-line-height: 1.3rem;

// OLD FONT SIZES
// Fonts Sizes equivalent to a major third scale: https://type-scale.com/
$small-font-size: 0.8rem;
$heading-1: 1.953rem;
$heading-2: 1.563rem;
$heading-3: 1.25rem;
$heading-4: 16px;
$heading-5: $small-font-size;
$heading-6: 0.64rem;
$heading-7: 0.512rem;

// New Spacing
$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-s: 12px; // was 16px;
$spacing-m: 16px; // was 24px;
$spacing-l: 24px;
$spacing-xl: 32px;
$spacing-xxl: 48px;

/*
Brand approved colours - only use these colours
*/

// Neutrals - brand approved
$transparent: #00000000;

$white: #ffffff;
$neutral0: #ffffff;
$neutral1: #fbfafa;
$neutral2: #f2f2f2;
$neutral3: #d7d9d9;
$neutral4: #878e8d;
$neutral5: #6e7473;
$neutral6: #555a5a;
$neutral7: #0c0d0d;

// Primary colours - brand approved
$primary1: #d9f0ed;
$primary2: #c7e9e5;
$primary3: #a6dcd5;
$primary4: #8dbbb5;
$primary5: #769c97;
$primary6: #4b6360;

// Secondary colours - brand approved
$secondary1: #ebeff9;
$secondary2: #abb8e5;
$secondary3: #3b5bc2;

// Risk / Error - brand approved
$risk1: #fff2f1;
$risk2: #ffd8d4;
$risk3: #e67369;
$risk4: #cc665e;
$risk5: #bf6058;
$risk6: #994d46;

// Unexpected / Warning - brand approved
$unexpected1: #fff8ea;
$unexpected2: #fee09c;
$unexpected3: #fcbc28;

// Expected / Success - brand approved
$expected1: #f2f9ea;
$expected2: #7dc629;

// Support - brand approved
$support1: #fefef6;
$support2: #f0f0a0;

// SU Avatar colours - do not use these
$brandYellow: #fefef6;
$brandYellow1: #f5f5bf;
$brandOrange: #fef3ef;
$brandOrange1: #f5ab90;
$brandBlue1: #ebeff9;
$brandBlue3: #abb8e5;

/*
Fonts
*/
$headerBody: 14px;
$headerBodySmall: 11.7px;
$heading1: 38px;
$heading2: 32px;
$heading3: 26px;
$heading4: 24px;
$heading5: 22px;
$subHeading: 20px;
$baseFontSize: 16px;
$body: 18px;
$bodyMedium: 16px;
$bodySmall: 14px;
$bodyExtraSmall: 12px;
$bodyExtraExtraSmall: 10px;
$caption: 14px;

/*
Font weights
*/
$light: 300;
$regular: 400;
$mediumWeight: 500;
$semiBold: 600;
$bold: 700;

/*
Line hieghts
*/
$superWide: 36px;
$extraWide: 26px;
$wide: 22px;
$mediumWide: 20px;
$medium: 18px;
$mediumThin: 16px;
$thin: 14px;
$extraThin: 12px;

:export {
  /*
  Brand approved colours
  */

  // Neutrals
  transparent: $transparent;
  white: $white;
  netural0: $neutral0;
  neutral1: $neutral1;
  neutral2: $neutral2;
  neutral3: $neutral3;
  neutral4: $neutral4;
  neutral5: $neutral5;
  neutral6: $neutral6;
  neutral7: $neutral7;

  // Primary colours
  primary1: $primary1;
  primary2: $primary2;
  primary3: $primary3;
  primary4: $primary4;
  primary5: $primary5;
  primary6: $primary6;

  // Secondary colours
  secondary1: $secondary1;
  secondary2: $secondary2;
  secondary3: $secondary3;

  // Risk / Error
  risk1: $risk1;
  risk2: $risk2;
  risk3: $risk3;
  risk4: $risk4;
  risk5: $risk5;
  risk6: $risk6;

  // Unexpected / Warning
  unexpected1: $unexpected1;
  unexpected2: $unexpected2;
  unexpected3: $unexpected3;

  // Expected / Success
  expected1: $expected1;
  expected2: $expected2;

  // Support
  support1: $support1;
  support2: $support2;

  // SU Avatar Colours - do not use these
  brandBlue1: $brandBlue1;
  brandBlue3: $brandBlue3;
  brandYellow: $brandYellow;
  brandYellow1: $brandYellow1;
  brandOrange: $brandOrange;
  brandOrange1: $brandOrange1;

  //Spacing
  spacing-xxs: $spacing-xxs;
  spacing-xs: $spacing-xs;
  spacing-s: $spacing-s;
  spacing-m: $spacing-m;
  spacing-l: $spacing-l;
  spacing-xl: $spacing-xl;
  spacing-xxl: $spacing-xxl;

  /*
  Fonts
  */
  headerBody: $headerBody;
  headerBodySmall: $headerBodySmall;
  heading1: $heading1;
  heading2: $heading2;
  heading3: $heading3;
  heading4: $heading4;
  heading5: $heading5;
  subHeading: $subHeading;
  baseFontSize: $baseFontSize;
  body: $body;
  bodyMedium: $bodyMedium;
  bodySmall: $bodySmall;
  bodyExtraSmall: $bodyExtraSmall;
  bodyExtraExtraSmall: $bodyExtraExtraSmall;
  caption: $caption;
  small_font_size: $small-font-size;

  /*
  Font weights
  */
  light: $light;
  regular: $regular;
  mediumWeight: $mediumWeight;
  semiBold: $semiBold;
  bold: $bold;

  /*
  Line hieghts
  */
  superWide: $superWide;
  extraWide: $extraWide;
  wide: $wide;
  mediumWide: $mediumWide;
  medium: $medium;
  mediumThin: $mediumThin;
  thin: $thin;
  extraThin: $extraThin;
}
