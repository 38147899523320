@import "../../Styles/theme.scss";

.error_page {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  gap: 16px;
}

.error_page_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  max-width: 500px;
}

.heading {
  margin: 0;
}

.email {
  color: $primary6;
}

@media (max-width: $mobile-break-point) {
  .errorPage {
    padding: $mobile-padding;
  }
}
