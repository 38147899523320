@import "../../Styles/theme.scss";

.lockout {
  margin: 30px 0 20px 0;
  p {
    font-size: $body;
    font-weight: $mediumWeight;
  }
}

.firstLogin {
  padding-left: 0;
}
