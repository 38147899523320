@import "../../Styles/theme.scss";

.component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.manual_entry {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 12px;
}

.manual_entry_message {
  font-weight: 400;
  font-size: 16px;
  color: $risk5;
  margin-bottom: 12px;
}

.qr_image {
  display: flex; 
  justify-content: center;
}

.information_link {
  text-decoration: underline;
  text-underline-offset: 4px;
  font-size: 16px;
  color: $primary6;
  cursor: pointer;
}

.modal_content {
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1100px){
  .qr_image {
    img {
      width: 90%;
    }
  }
}