@import "../../../Styles/theme.scss";

.desktop_instructions {
  display: flex;
  flex-direction: column;
}

.note {
  font-weight: $mediumWeight;
  span {
    font-weight: $regular;
    color: $neutral6;
  }
  margin-bottom: 16px;
}

.description {
  color: $neutral6;
  margin-top: 32px;
  margin-bottom: 32px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
