@import "../../Styles/theme.scss";

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.skeleton {
  background: linear-gradient(
    to right,
    $neutral1 0%,
    $neutral2 50%,
    $neutral1 100%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
}
