@import "../../../Styles/theme.scss";

.exit_install_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.warning_icon {
  display: flex;
  flex-direction: column;
  position: relative;
}

.cancel_icon_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $risk1;
  position: relative;
}

.cancel_icon_circle > svg {
  position: absolute;
  width: 33px;
  height: 33px;
  color: $risk3;
}

.title {
  font-family: "Inter";
  font-size: $heading4;
  font-weight: $mediumWeight;
  line-height: 33px;
  text-align: center;
  color: $neutral7;
  padding-top: 16px;
  padding-bottom: 16px;
}

.warning {
  font-family: "Inter";
  font-size: $bodyMedium;
  font-weight: $regular;
  line-height: 22px;
  text-align: center;
  color: $neutral7;
  padding-bottom: 32px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
