@import "../../../Styles/theme.scss";

.instructions_steps {
  display: flex;
  flex-direction: column;
  li {
    font-family: "Inter";
    font-size: $bodyMedium;
    font-weight: $regular;
    line-height: 22px;
    text-align: left;
    padding-bottom: 16px;
    color: $neutral6;
  }
  p {
    font-size: $bodyMedium;
    line-height: 22px;
    color: $neutral6;
  }
}