@import "../../../Styles/theme.scss";

.icon {
  padding-top: 24px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon_desktop {
  @extend .icon;
  padding-bottom: 28px;
}

.icon_text {
  font-family: "Inter";
  font-size: 28px;
  font-weight: $mediumWeight;
  padding-left: 12px;
}