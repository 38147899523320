@import "../../Styles/theme.scss";

.keys_container {
  display: flex;
  align-items: center;
  margin-top: 22px;
}

.title {
  font-family: Inter;
  font-style: normal;
  font-weight: $mediumWeight;
  font-size: $body;
  color: $neutral7;
}

.keys {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.key {
  display: flex;
}

.key_item {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.risk_key_container {
  display: flex;
  justify-content: center;
  padding: 4px 10px;
  color: $neutral7;
  font-family: Inter;
  font-style: normal;
  font-weight: $regular;
  border-radius: 1px;
}

.expected {
  background-color: $expected2;
}

.unexpected {
  background-color: $unexpected3;
}

.atRisk {
  background-color: $risk3;
}

.noData {
  background-color: $neutral4;
}
