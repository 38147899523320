@import "../../Styles/theme.scss";

.temperature_tooltip_container {
  border: 1px solid $secondary3;
  border-radius: 8px;
  background: $secondary1;
  color: $neutral7;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: normal;
  align-content: center;
  align-items: flex-start;
  padding: 2px 8px;
  margin-bottom: 24px;

  svg {
    color: $secondary3;
    margin-top: 16px;
    margin-left: 8px;
  }
}

.temperature_info {
  margin: 16px;
  display: flex;
  flex-direction: column;
}

.temperature_info_top {
  display: flex;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 12px;
}

.temperature_info_bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 400;
  div {
    margin-bottom: 8px;
  }
}

@media (max-width: 420px) {
  .bottom {
    display: flex;
    flex-direction: column;
  }
}
