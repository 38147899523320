@import "../../../Styles/theme.scss";

.confirm_card {
  margin-top: 20px;
  box-sizing: border-box;
  border: 1px solid $neutral3;
  border-radius: 4px;
  text-align: left;
  width: 100%;
  padding: 24px;
}

.confirm_address {
  font-weight: $regular;
  font-size: $bodyMedium;
  color: $neutral6;
}

.page {
  padding: 16px 20px;
  border: 1px solid $neutral2;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $neutral1;
  font-family: Inter;
  align-items: flex-start !important;
  label {
    font-size: $body;
    color: $neutral5;
    width: 100%;
  }
}

.logo {
  max-width: 300px;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.content {
  width: 100%;
  padding: 24px;
  display: flex;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
}

.select_SU_header {
  font-weight: $mediumWeight;
  font-size: $heading2;
  color: $neutral7;
  margin-top: 0;
  margin-top: 45px;
  margin-bottom: 24px;
}

.buttons {
  display: flex;
  gap: 10px;
  margin-top: 100px;
}

.card_content {
  min-width: 500px;
  display: flex;
  flex-direction: column;
}

@media (max-width: $mobile-break-point) {
  // The rest
  .page {
    background-color: $white;
    padding: 0;
  }
  .select_SU {
    align-items: flex-start;
  }
  .buttons {
    width: 100%;
    margin-bottom: 10%;
    flex-direction: column-reverse;
  }

  .card_content {
    min-width: unset;
    padding: 24px;
  }
}

.selectLabel {
  margin: 0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: $neutral6;
}

.continueInstallation {
  margin-top: 24px;
  color: $neutral6;
}
