@import "../../Styles/theme.scss";

@keyframes fadeIn {
  0% {
    opacity: 0;
    scale: 0.9;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    scale: 1;
  }
  100% {
    opacity: 0;
    scale: 0.9;
  }
}

.fade_in {
  animation: fadeIn 150ms ease-in-out; // a transition effect with a slow start and end
}
.fade_out {
  animation: fadeOut 200ms ease-out;
}

.container {
  position: fixed;
  z-index: 600;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300;
}

.modal {
  position: relative;
  z-index: 500;
  width: 65%;
  max-height: 85vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  padding: 36px;
  overflow-x: hidden;
}

.large_modal {
  @extend .modal;
  width: 90%;
  max-height: 90vh;
  height: 90vh;
}

.medium_modal {
  @extend .modal;
  width: 85%;
  max-height: 90vh;
}

.modal_close {
  position: absolute;
  top: 36px;
  right: 36px;
  cursor: pointer;
  z-index: 300;
  color: $neutral4;
}

.modal_close:hover {
  color: $risk5;
}

.modal_top {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.icon {
  margin-right: 12px;
}

.modal_title {
  color: $neutral7;
  font-size: $heading3;
  font-weight: $mediumWeight;
}

.modal_context {
  align-self: flex-start;
  margin-bottom: 24px;
  color: $neutral5;
  font-size: $bodySmall;
}

.modal_body {
  overflow-y: auto;
  scrollbar-width: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  flex: 1;
  scrollbar-color: $neutral3 $transparent;
  scrollbar-width: thin;
}

@media (max-width: $mobile-break-point) {
  .modal {
    width: 90%;
    padding: $spacing-m;
  }
  .modal_close {
    top: 12px;
    right: 12px;
  }
}
