@import "../../Styles/theme.scss";

.neutral5 {
  color: $neutral5;
}

.neutral7 {
  color: $neutral7;
}

.details {
  font-size: $bodySmall;
  line-height: 1.5;
}

.dark_text {
  color: $neutral7;
}

.white_text {
  color: $white;
  font-weight: $mediumWeight;
}

.grey_text {
  color: $neutral7;
}

.lighter_text {
  color: $neutral5;
}

.too_hot_contrast {
  color: $neutral7;
}

.day_container {
  border-radius: 2.55422px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.day_container_desktop {
  @extend .day_container;
  margin-right: 12px;
  padding: 8px;
  padding-left: 18px;
  min-width: 125px;
  max-width: 125px;
  min-height: 90px;
  max-height: 90px;
  box-sizing: border-box;
}

.day_container_desktop:last-child {
  margin-right: 0px;
}

.day_container_mobile {
  @extend .day_container;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: $mediumWeight;
  font-size: $bodyMedium;
  min-height: 50px;
  height: 90px;
  margin-right: 6px;
  margin-bottom: 6px;
}
.blank_sqaure {
  cursor: auto;
  border: 1px solid $white;
  color: $white;
  background: $white;
}
.at_risk {
  border: 1px solid $neutral2;
  color: $neutral7;
  background: $risk4;
}
.unexpected {
  border: 1px solid $neutral2;
  color: $neutral7;
  background: $unexpected3;
}
.expected {
  border: 1px solid $neutral2;
  color: $neutral7;
  background: $white;
}
.not_expected {
  background: $primary3;
  color: $neutral7;
  border: 1px solid $neutral2;
}
.too_hot {
  background: $risk2;
  border: 1px solid $neutral2;
}
.too_cold {
  background: $secondary2;
  border: 1px solid $neutral2;
}
.no_data {
  background: $neutral2;
  color: $neutral7;
  border: 1px solid $neutral2;
}
// Day component - Mobile
.blank_square_mobile {
  @extend .blank_sqaure;
  @extend .day_container_mobile;
}
.at_risk_mobile {
  @extend .at_risk;
  @extend .day_container_mobile;
}
.unexpected_mobile {
  @extend .unexpected;
  @extend .day_container_mobile;
}
.expected_mobile {
  @extend .expected;
  @extend .day_container_mobile;
}
.not_expected_mobile {
  @extend .not_expected;
  @extend .day_container_mobile;
}
.no_data_mobile {
  @extend .no_data;
  @extend .day_container_mobile;
}
.too_cold_mobile {
  @extend .day_container_mobile;
  @extend .too_cold;
}
.too_hot_mobile {
  @extend .day_container_mobile;
  @extend .too_hot;
}

// Day component - Desktop
.blank_square_desktop {
  @extend .blank_sqaure;
  @extend .day_container_desktop;
}
.not_expected_desktop {
  @extend .not_expected;
  @extend .day_container_desktop;
  padding-left: 20px !important;
}
.at_risk_desktop {
  @extend .at_risk;
  @extend .day_container_desktop;
}
.unexpected_desktop {
  @extend .unexpected;
  @extend .day_container_desktop;
}
.expected_desktop {
  @extend .expected;
  @extend .day_container_desktop;
}
.no_data_desktop {
  @extend .no_data;
  @extend .day_container_desktop;
  padding-left: 20px !important;
}
.too_cold_desktop {
  @extend .day_container_desktop;
  @extend .too_cold;
  color: $neutral7;
}
.too_hot_desktop {
  @extend .day_container_desktop;
  @extend .too_hot;
  color: $neutral7;
}

// Supporting styles - Day Component details
.date {
  font-weight: $mediumWeight;
  font-size: $bodyMedium;
}
.date_at_risk {
  color: $neutral7;
}
.date_not_at_risk {
  color: $neutral7;
}

// Day component details - used in Index.js
.date_at_risk_desktop {
  @extend .date;
  @extend .date_at_risk;
  margin-bottom: 5px;
}
.date_not_expected_desktop {
  @extend .date;
  color: $neutral7;
  margin-bottom: 5px;
}
.date_not_at_risk_desktop {
  @extend .date;
  @extend .date_not_at_risk;
  margin-bottom: 5px;
}
.date_at_risk_mobile {
  @extend .date;
  @extend .date_at_risk;
}
.date_not_at_risk_mobile {
  @extend .date_not_at_risk;
  @extend .date;
}

@media (max-width: 1440px) {
  .day_container_desktop {
    @extend .day_container;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: unset;
    max-width: unset;
    min-height: 90px;
    max-height: 90px;
  }
}

@media (max-width: 1184px) {
  .blank_square_desktop,
  .at_risk_desktop,
  .unexpected_desktop,
  .expected_desktop,
  .not_expected_desktop,
  .too_cold_desktop,
  .too_hot_desktop,
  .no_data_desktop {
    display: flex;
    min-width: unset !important;
    max-width: unset !important;
    width: 100% !important;
  }
}

@media (min-width: 1620px) {
  .blank_square_desktop,
  .at_risk_desktop,
  .unexpected_desktop,
  .expected_desktop,
  .not_expected_desktop,
  .too_cold_desktop,
  .too_hot_desktop,
  .no_data_desktop {
    display: flex;
    min-width: unset !important;
    max-width: unset !important;
    width: 100% !important;
  }
}

@media (max-width: 890px) {
  .day_container_mobile {
    height: 70px;
  }
}

@media (max-width: 640px) {
  .day_container_mobile {
    height: 60px;
  }
}

@media (max-width: 500px) {
  .day_container_mobile {
    height: 55px;
  }
}
