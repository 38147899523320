@import "../../Styles/theme.scss";

.contextClass {
  margin-top: 24px;
  font-size: $bodyMedium;
}

.delete {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h1 {
    margin-top: 16px;
  }
  p {
    margin-bottom: 32px;
  }
}

.button_group {
  display: flex;
  gap: 16px;
}
