@import "../../Styles/theme.scss";

.page {
  padding: 24px;
  color: $neutral7;
}

.explanation {
  margin-top: 16px;
  max-width: 800px;
}

.courier_notification {
  color: $neutral5;
  font-size: $bodyExtraSmall;
}

.courier_link {
  text-decoration: underline;
  text-underline-offset: 2px;
  color: $neutral5;
}

@media (max-width: $mobile-break-point) {
  .page {
    padding: 13px;
  }
}
