@import "../../../Styles/theme.scss";

.note {
  font-weight: $mediumWeight;
  span {
    font-weight: $regular;
    color: $neutral6;
  }
  margin-bottom: 16px;
}
