@import "../../Styles/theme.scss";

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  p {
    margin: 12px 0;
  }
}

.form_fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.field {
  div {
    font-size: $bodySmall;
  }
  .label {
    font-weight: 500;
    color: $neutral7;
    line-height: 21px;
    margin-bottom: 8px;
    font-size: $bodySmall;
  }
}

.field_check {
  display: flex;
  font-size: $bodySmall;
  align-items: center;
  span {
    margin-left: 6px;
    color: $neutral7;
  }
}

.shared_input {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  border: 1px solid $neutral3;
  border-radius: 8px;
  font-size: $bodySmall;
  background-color: $white;

  &:focus-visible {
    outline: none;
    border: 1px solid $primary3;
  }
}

.input {
  @extend .shared_input;
}

.text_area {
  @extend .shared_input;
  resize: none;
  width: 100%;
  height: 80px;
  line-height: 21px;
}

.input_dob {
  @extend .shared_input;
  border: none;
  outline: none;
  border: none;

  &:focus-visible {
    border: none;
    outline: none;
  }
}

.monitoring_text_area {
  padding-top: 24px;
}

.field_dob {
  padding-left: 12px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $neutral3;
  border-radius: 8px;
  font-size: $bodySmall;
  background-color: $white;

  &:focus-within {
    border: 1px solid $primary3;
  }
}

.submit {
  align-self: flex-end;
  margin-top: 12px !important;
}
.error {
  color: red;
  font-size: $bodyExtraSmall;
}

.tip {
  font-size: $bodyExtraSmall;
  color: $neutral5;
}

.checkbox:checked ~ {
  background-color: red;
}

.users {
  display: flex;
  flex-wrap: wrap;
}

.user {
  padding: 6px;
  margin-right: 10px;
  margin-top: 10px;
  border: 1px solid $neutral2;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.deleteStaff {
  width: 15px;
  cursor: pointer;
  color: $neutral4;
  margin-right: 6px;
}

.warning {
  margin-top: 12px;
}

.behaviour_container {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.behaviour_dropdown_section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
}

@media (max-width: $tablet-break-point) {
  .monitoring_text_area {
    padding-top: 18px;
  }
  .form_fields {
    grid-template-columns: 1fr;
  }

  .behaviour_dropdown_section {
    grid-template-columns: 1fr;
  }

  .behaviour_daynightime_section {
    width: 100%;
  }
}

.behaviour_text {
  color: $neutral7;
}
