@import "../../../Styles/theme.scss";

// install page
.page {
  padding: 16px 20px;
  border: 1px solid $neutral2; // different
  border-radius: 8px; // different
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $neutral1;
  font-family: Inter;
  align-items: flex-start !important;
  label {
    font-size: $body;
    color: $neutral5;
    width: 100%;
  }
}

// install link device page
.page {
  padding: 24px; // different
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $neutral1;
  font-family: Inter;
  align-items: flex-start !important;
}

@media (max-width: $mobile-break-point) {
  // install, install link device pages
  .page {
    background-color: $white;
    padding: 0;
  }
}
