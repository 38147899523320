@import "../../Styles/theme.scss";

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
}

.banner {
  background-color: $support1;
  border: 1px solid $support2;
  border-radius: 8px;
  padding: 12px 15px;
  color: $neutral7;
  font-size: $bodySmall;
  display: flex;
  gap: 12px;
}

.bold {
  font-weight: $mediumWeight;
}

.label {
  font-size: $bodyMedium;
  line-height: $wide;
  color: $neutral6;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.container {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.header {
  color: $neutral7;
  font-size: $heading4;
  font-weight: $mediumWeight;
  line-height: $wide;
  margin-top: 35px;
  margin-bottom: 24px;
}

.step_one_content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.text {
  color: $neutral6;
  margin: 0;
  padding: 0;
}

.error_text {
  @extend .text;
  color: $risk6;
}

.email {
  color: $primary5;
  font-weight: $mediumWeight;
  text-decoration: underline;
}

.reports_link {
  text-decoration: underline;
  cursor: pointer;
}

.super {
  vertical-align: super;
}

.input {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  border: 1px solid $neutral3;
  border-radius: 8px;
  font-size: $bodySmall;
  background-color: $white;

  &:focus-visible {
    outline: none;
    border: 1px solid $primary3;
  }
}
