@import "../../Styles/theme.scss";

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 300;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
