@import "../../Styles/theme.scss";

.form {
  display: flex;
  flex-direction: column;
}

// Hide the scrollbar but still allow scrolling
.form::-webkit-scrollbar {
  display: none;
}

.form_fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.field {
  div {
    font-size: $bodySmall;
  }
  .label {
    color: $neutral7;
  }
}

.input,
.text_area {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  border: 1px solid $neutral2;
  border-radius: 2px;
  font-size: $bodySmall;
  background-color: #fafafa;
}

.input_dob {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  border: none;
  font-size: $bodySmall;
  background-color: #fafafa;
}

.field_dob {
  padding-left: 12px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $neutral2;
  border-radius: 2px;
  font-size: $bodySmall;
  background-color: #fafafa;
}

.label {
  color: $neutral7;
}

.submit {
  align-self: flex-end;
  margin-top: 12px !important;
}
.error {
  color: red;
  font-size: $bodyExtraSmall;
}

.tip {
  font-size: $bodyExtraSmall;
  color: $neutral5;
}

.monitoring_text_area {
  padding-top: 24px;
}

textarea {
  font-family: "Inter" !important;
  font-size: $bodySmall !important;
  color: $neutral7 !important;
  width: 100%;
  height: 80px;
  line-height: 21px;
}

.users {
  display: flex;
  flex-wrap: wrap;
}

.user {
  padding: 6px;
  margin-right: 10px;
  margin-top: 10px;
  border: 1px solid $neutral2;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.deleteStaff {
  width: 15px;
  cursor: pointer;
  color: $neutral4;
  margin-right: 6px;
}

.button_group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
}
