@import "../../Styles/theme.scss";

.notifications {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $white;
  padding: 35px 37px 50px 37px;
  color: $neutral7;
  h1 {
    font-size: $heading3;
    font-weight: $mediumWeight;
    margin-bottom: 6px;
    margin-top: 0;
  }
  h2 {
    font-size: $subHeading;
    font-weight: $mediumWeight;
    line-height: 20px;
    margin-bottom: 10px;
    margin-top: 0;
  }
  p {
    color: $neutral6;
    font-size: $bodySmall;
    font-weight: $regular;
    line-height: 22px;
    margin: 0;
  }

  // needed here to override css specificity of the notifications > p
  .notifications_error_message {
    color: $risk6;
    margin-top: 14px;
  }

  .notifications_success_message {
    color: $primary6;
    margin-top: 14px;
  }
}

.night_time_subtitle {
  margin: 16px 0px 8px 0px !important;
}

.nightHours {
  padding: 32px;
  border-radius: 16px;
  border: 2px solid $neutral2;
  align-self: start;
  max-width: 560px;
  box-sizing: border-box;
  margin: 40px 0;
}

.time_selector {
  display: flex;
  gap: 16px;
}

.time_selector_group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  input {
    color: $neutral7;
    font-size: 18px;
    font-family: "Inter";
    font-weight: $mediumWeight;
    line-height: 22.99px;
    padding: 12px 16px;
  }
}
.time {
  margin-right: 10px;
}

.orgAlerts {
  border-radius: 16px;
  padding: 24px 32px;
  background-color: $neutral1;
  display: flex;
  gap: 40px;
  p {
    margin-bottom: 0;
  }
  label {
    font-size: $bodySmall;
    font-weight: $semiBold;
  }
}
.orgAlerts_text {
  max-width: 550px;
  margin-right: 32px;
}

.details {
  font-size: $bodyMedium;
  color: $neutral6;
  margin-bottom: 12px;
}

.toggle {
  display: flex;
  flex-direction: column;
  align-self: center;
}

@media (max-width: $mobile-break-point) {
  .orgAlerts {
    flex-direction: column;
  }
  .orgAlerts_text {
    margin-right: 0;
  }

  .toggle {
    align-self: flex-start;
  }

  .time_selector {
    flex-direction: column;
  }

  .time_selector_group {
    justify-content: center;
  }

  .nightHours {
    align-self: center;
  }
}

.time_button {
  margin-left: 24px;
  padding: 12px 24px;
}
