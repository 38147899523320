@import "../../Styles/theme.scss";

.sensor_status_tag {
  display: flex;
  height: auto;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: $neutral7;
  gap: 8px;

  cursor: pointer;
}

.noHover {
  cursor: default;
}

.label {
  margin: 0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.online {
  background-color: $expected1;
}

.offline {
  background-color: $risk1;
}

.error {
  background-color: $neutral2;
  color: $neutral5;
}

.lowBattery {
  background-color: $unexpected1;
}

.hovered.offline {
  background-color: $risk2;
}

@media (hover: hover) {
  .online:hover:not(.hovered) {
    background-color: #e0f3c0;
  }

  .offline:hover:not(.hovered) {
    background-color: $risk2;
  }

  .warning:hover:not(.hovered) {
    background-color: $unexpected2;
  }

  .error:hover:not(.hovered) {
    background-color: $neutral3;
  }
}
