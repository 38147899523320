@import "../../Styles/theme.scss";

.page {
  width: 100%;
  height: 100%;
  background-color: $neutral7;
}

/* 1000px x 1414px is the size of the Care Act Report (reports v2) */
.pdf {
  width: 1000px;
  height: 1414px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow: hidden;
  page-break-after: always;
  position: relative;
}
