@import "../../Styles/theme.scss";

.sensor_sticker {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border: 1px solid $neutral2;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $bodyExtraSmall;
  font-weight: $mediumWeight;
  color: $neutral6;
}
