@import "../../../Styles/theme.scss";



.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Inter";
  font-size: $heading2;
  font-weight: $mediumWeight;
  line-height: 48px;
  text-align: left;
  color: $neutral7;
  padding-top: 16px;
  padding-bottom: 16px;
}

.icon {
  margin-right: 10px;
}

.sensor_text {
  font-family: "Inter";
  font-size: $body;
  font-weight: $regular;
  line-height: 26px;
  text-align: left;
  color: $neutral6;
  padding-bottom: 20px;
}

.hub_is_connecting {
  padding-top: 12px;
}

@media (max-width: 400px) {
  .sensor_text {
    height: unset;
  }
}
