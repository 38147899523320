@import "../../../Styles/theme.scss";

.sensor_squares_container {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.sensor_squares {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr)); 
  row-gap: 22px;
  padding-top: 16px;
  padding-bottom: 22px;
}

.square {
  justify-self: start;
}
