@import "../../Styles/theme.scss";

// Page and Report containers
.page {
  align-items: stretch;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;
  max-height: calc(100vh - 65px);
}

.report {
  overflow: auto;
  background-color: $neutral2;
  margin: 0px 0px;
  flex: 1;
}

.align_center {
  align-self: center;
}

.error_fetching_report {
  height: 100vh;
}
